<template>
	<PageLayout :title="$t('account-statement.title')" x-large-width>
		<div class="account-statement">
			<div class="account-statement-header">
				<date-picker
					v-model="time"
					:editable="false"
					:clearable="false"
					:popup-style="{
						background: '#2a2e31',
						color: 'white',
						borderRadius: '5px',
						border: 'none',
					}"
					range
					@input="onDateChanged"
				/>
				<button class="export-account-statement" @click="exportStatement">
					<img src="../../assets/export.svg" />
					<span>{{ $t('actions.export-xslx') }}</span>
				</button>
			</div>
			<table class="account-statement-table">
				<thead>
					<tr>
						<th>{{ $t('account-statement.type') }}</th>
						<th>{{ $t('account-statement.amount') }}</th>
						<th>{{ $t('account-statement.details') }}</th>
						<th>{{ $t('account-statement.open-price') }}</th>
						<th>{{ $t('account-statement.close-price') }}</th>
						<th>{{ $t('account-statement.side') }}</th>
						<th>{{ $t('account-statement.date') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="record in records" :key="record.id" class="account-statement-table-row">
						<td class="border-left">
							{{ record.type }}
						</td>
						<td v-if="record.type === 'WITHDRAWAL'">
							-{{ formatCurrency($i18n.locale, record.currency, record.amount) }}
						</td>
						<td v-if="record.type !== 'WITHDRAWAL'">
							{{ formatCurrency($i18n.locale, record.currency, record.amount) }}
						</td>
						<td>{{ record.details }}</td>
						<td>{{ record.openPrice || '-' }}</td>
						<td>{{ record.closePrice || '-' }}</td>
						<td>{{ record.side || '-' }}</td>
						<td class="border-right">
							<strong>{{ formatDate(record.date).hours }}</strong> {{ formatDate(record.date).year }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</PageLayout>
</template>

<script>
import { DateTime } from 'luxon';
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '@/api';
import DatePicker from 'vue2-datepicker';
import fileDownload from 'js-file-download';

export default {
	name: 'AccountStatement',
	components: {
		PageLayout,
		DatePicker,
	},
	data() {
		return {
			records: [],
			time: null,
			loading: false,
		};
	},
	async mounted() {
		await this.fetchStatement();
	},
	methods: {
		async fetchStatement() {
			const now = new Date();
			const minusMonth = new Date();
			now.setDate(now.getDate() - 30);
			this.time = [now, minusMonth];
			try {
				const response = await apiClient.getAccountStatement(now, minusMonth);
				this.records = response.statementRecords;
			} finally {
				this.loading = false;
			}
		},
		exportStatement: async function () {
			const [start, end] = this.time;
			const { data, contentDisposition } = await apiClient.exportAccountStatement(start, end);
			const filename = this.getFilenameFromHeader(contentDisposition, `report-${new Date()}.xlsx`);

			fileDownload(new Blob([data]), filename);
		},
		onDateChanged: async function (range) {
			const [start, end] = range;
			const response = await apiClient.getAccountStatement(start, end);
			this.records = response.statementRecords;
		},
		formatCurrency: (locale, currency, amount) =>
			Intl.NumberFormat(locale, { style: 'currency', currency }).format(amount),
		formatTime: (value) => {
			if (value < 10) {
				return `0${value}`;
			}
			return value;
		},
		formatDate: (date) => {
			const dateTime = DateTime.fromISO(date);

			return {
				hours: dateTime.toFormat('HH:mm:ss'),
				year: dateTime.toFormat('LLL dd, yyyy'),
			};
		},
		getFilenameFromHeader: (dispositionHeader, defaultFilename) => {
			let filename = defaultFilename;
			if (dispositionHeader && dispositionHeader.startsWith('attachment')) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(dispositionHeader);
				if (matches !== null && matches[1]) {
					filename = matches[1].replace(/['"]/g, '');
				}
			}
			return filename;
		},
	},
};
</script>

<style scoped lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

@mixin flex-space-around-center {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.currency {
	color: red;
}

.border-left {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.border-right {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.account-statement-header {
	width: 100%;
	display: flex;
}

.account-statement {
	padding-right: 20px;
	overflow: auto;
	font-size: 14px;

	&-table {
		width: 100%;
		text-align: left;
		border-collapse: separate;
		border-spacing: 0 15px;
		min-width: 600px;

		& > thead > tr > th {
			padding-left: 20px;
			height: 40px;
		}

		&-row {
			background: #2a2e31;
			height: 40px;
			padding-bottom: 20px;
			& > td {
				padding-left: 20px;
			}
		}
	}
}

.export-account-statement {
	background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%), #2a2e31;
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 5px;
	display: flex;
	align-items: center;
	color: white;
	height: 34px;
	margin-left: 5px;
	padding-right: 15px;
	font-size: 14px;
}

::v-deep .mx-input {
	background-color: #2a2e31;
	border: 1px solid rgba(255, 255, 255, 0.1);
	color: white;
}

::v-deep .mx-icon-calendar {
	color: white;
}
</style>
